<template>
  <div>
    <div class="row">
      <div class="col-6">
        <b-input placeholder="filtrar / buscar" v-model="filtro" autocomplete="off" id="filexamemed" name="filtexamemed" />
      </div>
      <div class="col">
        <b-btn class="float-right" variant="primary" @click="$emit('novo')"
          ><i class="fas fa-plus"></i> Adicionar Produto/Exame/Consulta</b-btn
        >
      </div>
    </div>

    <div class="container">
      <!-- <div
      
        class="card"
        v-for="exame in examesFiltrados"
        :key="`exame_${exame._idInt}`"
      >
      
        <div class="icon" @click="editar(exame)">
          <i :class="`fas fa-${iconByTipo(exame.tipo)}`"></i>
        </div>
       
        <div class="content"   @click="editar(exame)">
          <div class="item text-nowrap">
            <label> <i class="fas fa-vial"></i> Nome:</label>
            {{ exame.nome ? exame.nome : "Sem nome" }}
          </div>
             <div class="excluir">
           <i class="fas fa-times"></i>
          </div> -->
      <!--
          <div class="item">
            <label> Tipo:</label>
            <div class="text-nowrap">
              {{ exame.tipo }}
            </div>
          </div>
          <div class="item">
            <div>
              <label> Valor Venda (R$):</label>
              <div class="text-nowrap">
                {{ exame.valor_venda | currencyWithMask }}
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <label> Valor Custo (R$):</label>
              <div class="text-nowrap">
                {{ exame.valor_custo | currencyWithMask }}
              </div>
            </div>
          </div>
        </div>
        
          <b-btn variant="transparent" @click="excluir(exame.id)"  class="actions">
          <i class="fas fa-trash"></i>
        </b-btn>
      </div> -->
    </div>

    <b-radio-group
      class="m-3"
      :options="['Todos', 'Exame', 'Consulta', 'Produto']"
      v-model="filtroTipo"
    />

    <b-table
      :items="examesFiltrados"
      :fields="campos"
      responsive="sm"
      stacked="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      small
      style="font-size: 11px"
      class="mt-2"
    >
      <template #table-busy class="text-center">
        <b-spinner />
      </template>
      <template #cell(valor_venda)="{ item }">
        {{ item.valor_venda | currencyWithMask }}
      </template>
      <template #cell(valor_custo)="{ item }">
        {{ item.valor_custo | currencyWithMask }}
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          variant="warning"
          @click="editar(row.item)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <b-button
          size="sm"
          variant="danger"
          @click="excluir(row.item.id)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        >
      </template>
    </b-table>
    <div class="d-flex justify-content-between align-items-baseline">
      <b-pagination
        v-model="paginacao.pagina_atual"
        :total-rows="examesFiltrados.length"
        :per-page="paginacao.registros_por_pagina"
      >
      </b-pagination>
      <!--
      <b-btn variant="primary" @click="$emit('novo')"
        ><i class="fas fa-plus"></i
      ></b-btn> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filtro: String,
    exames: Array,
  },
  data() {
    return {
      loading: false,
      lista: [],
      filtroTipo: "Todos",
      campos: [
        { key: "nome", label: "Nome", class: "text-center text-nowrap" },
        {
          key: "descricao",
          label: "Descrição",
          class: "text-center text-nowrap",
        },
        { key: "tipo", label: "Tipo", class: "text-center text-nowrap" },
        {
          key: "valor_venda",
          label: "Valor Venda",
          class: "text-center text-nowrap",
        },
        {
          key: "valor_custo",
          label: "Valor Custo",
          class: "text-center text-nowrap",
        },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
    };
  },
  methods: {
    editar(exame) {
      this.$emit("editar", exame);
    },
    excluir(exame) {
      this.$emit("excluir", exame);
    },
    iconByTipo(tipo) {
      if (tipo == "Exame") {
        return "file-medical";
      }
      if (tipo == "Consulta") {
        return "id-card-alt";
      }
      if (tipo == "Produto") {
        return "box";
      }
    },
  },
  computed: {
    examesFiltrados() {
      return this.exames
        .filter((ex) => {
          // console.log("EXXXXXXXXXX", ex._rowStatus);
          if (ex._rowStatus) {
            if (ex._rowStatus === "deleted") {
              return false;
            }
          }
          if(this.filtroTipo && this.filtroTipo != 'Todos' && ex.tipo != this.filtroTipo){
            return false;
          }
          return true;
        })
        .sort((a, b) => {
          return a.nome.trim().localeCompare(b.nome.trim())
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cards.scss";
</style>
