<template>
  <div>
    <h3 class="text-rigth text-muted">
      O que é a <b>personalização de desconto de fidelização</b>?
    </h3>
    <h4 class="text-center">
      O sistema de fidelização é bem simples, e funciona basicamente assim:
    </h4>
    <!-- <ul>
      <li>O cliente compra a fidelidade</li>
      <li>Quando ele aplicar </li>
      <li></li>
    </ul> -->
    <img :src="require('@/assets/img/tutorials/cartaoFidelidade.png')" width="100%" alt="">
  </div>
</template>

<script>
export default {};
</script>

<style></style>
