<template>
  <div>
    <!-- {{exames}} -->
    <ExamesListagem
      @novo="novo()"
      :exames="exames"
      @editar="editarEntidadeExame($event)"
      @excluir="excluirEntidadeExame($event)"
    />
    <b-modal
      id="modal-exames-select"
      size="lg"
      hide-footer
      no-close-on-backdrop
      no-enforce-focus
    >
      <template #modal-title>
        <i class="fas fa-hand-holding-medical"></i>
        <!-- {{ typeof exame_edit.id === "number" ? "Editar" : "Adicionar" }} -->
        Adicionar Produto/Serviço
      </template>

      <ExamesSelect
        @adicionar="adicionarProduto()"
        @editar="editarExame($event)"
        @salvar="salvar($event)"
        :editObj="exame_edit_obj"
        ref="exameSelect"
        :entidade_id="entidade_id"
        :aceitaFidelizacao="aceitaFidelizacao"
      />
    </b-modal>

    <EmptyState v-if="exames.length === 0">
      <h3>
        Nenhum produto cadastrado!
      </h3>
      <p>
        Clique em
        <b-button class="mx-1" variant="primary" @click="novo()">
          <span class="fas fa-plus"></span>
        </b-button>
        para cadastrar.
      </p>
    </EmptyState>
  </div>
</template>

<script>
import ExamesListagem from "./ExamesListagem.vue";
import ExamesSelect from "./ExamesSelect.vue";

import EmptyState from "../../common/EmptyState.vue";

export default {
  props: {
    examesProp: Array,
    aceitaFidelizacao: { type: Boolean, default: false },
    entidade_id: { type: [String, Number], required: false },
  },
  components: {
    ExamesListagem,
    ExamesSelect,
    EmptyState,
  },
  data() {
    return {
      examesDisponiveis: [],

      exame_edit_obj: {},

      exames: [],
    };
  },

  async created() {
    // await this.carregaExames();

    if (this.examesProp && this.examesProp.length > 0) {
      this.exames = JSON.parse(
        JSON.stringify(
          this.examesProp.map((ex, i) => {
            return { ...ex, _idInt: i };
          })
        )
      );
      // this.examesProp.forEach((exames) => {
      //   this.exames.push(Object.assign({}, exames));
      // });
    }
  },
  methods: {
    novo() {
      (this.exame_edit_obj = {
        id: "",
        nome: "",
        tipo: "",
        especialidade_id: null,
        valor_venda: 0.0,
        valor_custo: 0.0,
        configuracoes: {
          fidelizacoes: [],
        },
        aceita_desconto: 0,
        justificativas: [],
      }),
        this.$bvModal.show("modal-exames-select");
    },
    adicionarProduto() {
      this.limpar();
      this.$bvModal.show("modal-exames");
    },
    async mudaExameSelecionado(exame) {
      // console.log("novo exame: ", exame);
      this.$forceUpdate();
      await this.$nextTick();
      //await this.carregaExames();

      await this.$refs.exameSelect.selecionaExame(exame);
    },
    limpar() {
      for (const key of Object.keys(this.exame_edit_obj)) {
        if (key !== "_rowStatus") this.exame_edit_obj[key] = null;
      }
    },

    async editarExame(id) {
      if (typeof id === "number") {
        await this.carregaExames();
        this.exame_edit = this.examesDisponiveis.find((x) => x.id == id);
        this.exame_edit.valor_venda = this.formatValorReal(
          this.exame_edit.valor_venda
        );
        this.exame_edit.valor_custo = this.formatValorReal(
          this.exame_edit.valor_custo
        );

        // console.log('exame edit', this.exame_edit);

        this.$bvModal.show("modal-exames");
      } else {
        this.showToast("error", "Selecione um exame antes!");
      }
    },
    async editarEntidadeExame(exame) {
      // Object.assign(this.entidade_exame_edit, {
      //   ...this.exames[id],
      //   index: id,
      // });

      // this.entidade_exame_edit.valor_venda = this.formatValorReal(
      //   this.entidade_exame_edit.valor_venda
      // );
      // this.entidade_exame_edit.valor_custo = this.formatValorReal(
      //   this.entidade_exame_edit.valor_custo
      // );
      this.exame_edit_obj = Object.assign({}, { ...exame });
      this.$bvModal.show("modal-exames-select");
    },
    async excluirEntidadeExame(id) {
      const idx = this.exames.findIndex((e) => e.id == id);
      console.log("EXCLUIR O IDX -->", idx);
      if (idx !== -1) {
        await this.toastConfirmacao(
          () => {
            if (this.exames[idx]._rowStatus) {
              this.exames.splice(idx, 1);
            } else {
              this.$set(this.exames[idx], "_rowStatus", "deleted");
            }

            this.$emit("valueChanged", this.exames);
            this.$forceUpdate();
          },
          () => {}
        );
      }
    },
    close() {
      this.$bvModal.hide("modal-exames");
    },
    salvar(exame) {
      // console.log("exame recebidos", exame.index);
      console.log("exame recebido", exame);
      console.log("exames atuais", this.exames);
      if (exame._idInt >= 0) {
        const idx = this.exames.findIndex((e) => exame._idInt === e._idInt);
        console.log("idx", idx);
        if (idx === -1) return;
        this.exames[idx] = exame;
        this.$set(this.exames, idx, exame);
        // delete this.exames[idx].index;
        // delete exame.index;
      } else {
        const idInt =
          this.exames.length > 0
            ? Math.max(...(this.exames.map((e) => e._idInt) + 1))
            : 0;
        console.log("Novo idInt ---> ", idInt);
        exame._idInt = idInt;

        this.exames.push(exame);
      }

      this.$bvModal.hide("modal-exames-select");
      this.$emit("valueChanged", this.exames);
      this.$forceUpdate();
    },
  },
};
</script>

<style></style>
