<template>
  <div class="container-fluid">
    <b-tabs>
      <b-tab title="Cadastro" class="p-3">
        <b-form-group>
          <div class="row align-items-center">
            <div class="col">
              <label>Selecionar Produto/Serviço</label>
              <select-exames
                :value="exame"
                :disabled="!!exame_edit.id"
                :selecionado="exame.id"
                :showBotaoEdit="false"
                showBotaoSearch
                @select="exameChanged($event)"
                :minLength="0"
                description="Comece a digitar para buscar. (busque pelo Nome, Tipo, Descrição)"
              >
              </select-exames>
              <!-- <v-select
                :options="examesDisponiveis"
                @input="exameChanged"
                :value="exame"
                label="nome"
                :disabled="!!exame.id_entidades_exames"
                :loading="loadingExames"
              >
                <template #search="{ attributes, events }">
                  <input v-bind="attributes" v-on="events" class="vs__search" />
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Nenhum resultado para <em>{{ search }}</em
                    >.
                  </template>
                  <em style="opacity: 0.5" v-else
                    >Comece a digitar para procurar um produto/serviço.</em
                  >
                </template>
              </v-select> -->
            </div>
            <!-- <div class="d-flex align-items-bottom mt-4">
              <b-btn
                :disabled="!!exame.id"
                variant="primary"
                class="mr-2"
                @click="$emit('adicionar')"
              >
                <i class="fas fa-plus"></i
              ></b-btn>

              <b-btn variant="warning" @click="editarExame()"
                ><i class="fas fa-edit"></i
              ></b-btn>
            </div> -->
          </div>
        </b-form-group>
        <b-form-group>
          <div class="row">
            <div class="col">
              <SelectEspecialidade v-model="exame.especialidade_id" />
            </div>
          </div>
        </b-form-group>
        <b-form-group>
          <div class="row ">
            <div class="col">
              <label>Valor Venda</label>
              <money
                v-model="$v.exame.valor_venda.$model"
                :state="validateState('valor_venda')"
                class="form-control "
                v-bind="moneyMask"
              ></money>

              <small
                class="text-danger"
                v-if="
                  exame.valor_venda != exame_edit.valor_venda &&
                    !exame.justificativa &&
                    exame_edit.id
                "
                >* O valor de venda foi alterado, será necessário informar uma
                justificativa ao salvar.</small
              >
            </div>

            <div class="col">
              <label>Valor Custo</label>
              <money
                v-model="$v.exame.valor_custo.$model"
                :state="validateState('valor_custo')"
                v-bind="moneyMask"
                class="form-control "
              ></money>
              <small
                class="text-danger"
                v-if="
                  exame.valor_custo != exame_edit.valor_custo &&
                    !exame.justificativa &&
                    exame_edit.id
                "
                >* O valor de venda foi alterado, será necessário informar uma
                justificativa ao salvar.</small
              >
            </div>
          </div>
        </b-form-group>

        <b-form-group>
          <div class="row mt-2">
            <div class="col">
              <b-form-checkbox
                v-model="exame.aceita_desconto"
                :value="1"
                :disabled="!aceitaFidelizacao"
                :unchecked-value="0"
                switch
              >
                Personalizar Desconto de Fidelização</b-form-checkbox
              >
              <small class="text-muted" v-if="!aceitaFidelizacao">
                * <b>Atenção</b> O médico não aceita fidelização, caso queira
                ativar vá em <b>Configurações / Aceita Fidelização</b>
              </small>
            </div>

            <div
              class="col d-flex justify-content-end"
              v-if="exame.aceita_desconto"
            >
              <b-btn
                @click="onPersonalizaDesconto"
                size="sm"
                class="rounded"
                variant="primary"
              >
                <b-icon
                  :animation="loadingFidelizacoes ? 'spin' : ''"
                  icon="arrow-clockwise"
                ></b-icon>
                Atualizar
              </b-btn>
            </div>
          </div>
        </b-form-group>
        <div class="row " v-if="exame.aceita_desconto && aceitaFidelizacao">
          <div class="col-12 justify-content-start">
            <b-btn v-if="false" @click="openInfo" size="sm" variant="info">
              <i class="fas fa-info"></i> Ajuda
            </b-btn>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <span class="text-danger tex-center" v-if="changedValorVenda">
              * <b>Atenção!! </b> O valor de venda foi alterado, verifique os
              valores personalizados!
            </span>
            <small class="text-muted">
              Esses valores serão os valores personalizados para
              <b>CADA FIDELIZAÇÃO</b> cadastrada no sistema
            </small>
          </div>

          <div class="col">
            <b-table
              :fields="campos"
              small
              class=""
              :items="exame.configuracoes.fidelizacoes"
              show-empty
              :busy="loadingFidelizacoes"
            >
              <template #table-busy>
                <div class="d-flex justify-content-center align-items-center">
                  <b-spinner class="mr-2" small></b-spinner> Carregando
                  fidelizações e personalizando descontos...
                </div>
              </template>
              <template #cell(nome)="{item}">
                {{ item.nome }}
                <span class="text-danger text-right">
                  {{ item.desconto ? "-" + item.desconto : "" }}</span
                >
              </template>
              <template #cell(valor_custo)="{item}">
                <money
                  class="form-control form-control-sm"
                  v-bind="moneyMask"
                  size="sm"
                  v-model="item.valor_custo"
                ></money>
              </template>
              <template #cell(valor_venda)="{item}">
                <money
                  class="form-control form-control-sm"
                  v-bind="moneyMask"
                  size="sm"
                  v-model="item.valor_venda"
                ></money>
              </template>
            </b-table>
          </div>
        </div>

        <hr />
        <!-- Desconto por Forma de Pagamento -->

        <b-form-group>
          <div class="row mt-2">
            <div class="col">
              <b-form-checkbox
                v-model="exame.personalizar_por_forma"
                :value="1"
                :unchecked-value="0"
                switch
              >
                Desconto por Forma de Pagamento</b-form-checkbox
              >
            </div>
          </div>
        </b-form-group>
        <div class="row " v-if="exame.personalizar_por_forma">
          <div class="col-12 justify-content-start">
            <b-btn v-if="false" @click="openInfo" size="sm" variant="info">
              <i class="fas fa-info"></i> Ajuda
            </b-btn>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <span class="text-danger tex-center" v-if="changedValorVenda">
              * <b>Atenção!! </b> O valor de venda foi alterado, verifique os
              valores personalizados!
            </span>
            <small class="text-muted">
              Esses valores serão os valores personalizados para
              <b>CADA FORMA DE PAGAMENTO</b> usado na venda
            </small>
          </div>

          <div class="col mb-0 pb-0">
            <b-table
              :fields="camposPersonalizarPorForma"
              small
              class=""
              :items="exame.configuracoes.personalizarPorForma"
              show-empty
              :busy="false"
            >
              <template #table-busy>
                <div class="d-flex justify-content-center align-items-center">
                  <b-spinner class="mr-2" small></b-spinner> Carregando formas
                  de pagamento e personalizando descontos...
                </div>
              </template>
              <template #empty>
                <div class="d-flex justify-content-center align-items-center">
                  Não há nenhuma personalização por forma de pagamento
                </div>
              </template>
              <template #cell(formaDePagamento)="{item}">
                <b-form-select
                  v-model="item.formaDePagamento"
                  :options="optionsPagamento"
                  size="sm"
                ></b-form-select>

                <span class="text-danger text-right">
                  {{ item.desconto ? "-" + item.desconto : "" }}</span
                >
              </template>
              <template #cell(valor_custo)="{item}">
                <money
                  class="form-control form-control-sm"
                  v-bind="moneyMask"
                  size="sm"
                  v-model="item.valor_custo"
                  :readonly="item.valorDeCusto"
                ></money>
              </template>
              <template #cell(valor_venda)="{item}">
                <money
                  class="form-control form-control-sm"
                  v-bind="moneyMask"
                  size="sm"
                  v-model="item.valor_venda"
                  :readonly="item.valorDeCusto"
                ></money>
                {{ item.pp }}
                <b-checkbox
                  switch
                  v-model="item.valorDeCusto"
                  @change="
                    (ev) => {
                      if (ev === true) {
                        item.valor_venda = 0;
                        item.valor_custo = exame.valor_custo;
                      } else {
                        item.valor_venda = exame.valor_venda;
                        item.valor_custo = exame.valor_custo;
                      }
                    }
                  "
                  >Igual ao custo</b-checkbox
                >
              </template>
              <template #cell(empresa)="{item}">
                <b-btn
                  class="mx-auto d-block"
                  @click="selecionarEmpresa(item)"
                  size="sm"
                  variant="info"
                  v-if="!item.empresa || !item.empresa.id"
                >
                  <i class="fas fa-search"></i> Selecionar
                </b-btn>

                <div
                  class="d-flex-flex-column"
                  v-if="item.empresa && item.empresa.id"
                >
                  <div class="d-flex justify-content-between">
                    <b-form-input
                      readonly
                      :value="item.empresa.razao_social"
                      size="sm"
                    ></b-form-input>
                    <b-btn
                      @click="removeEmpresa(item)"
                      size="sm"
                      class="rounded"
                      variant="primary"
                    >
                      <i class="fas fa-times"></i
                    ></b-btn>
                  </div>
                  <small v-if="item.empresa.id > -1" class="text-mute">
                    <b>ID: </b>{{ item.empresa.id }}
                  </small>
                </div>
              </template>

              <template #cell(acumulativo)="{item, index}">
                <b-form-group
                  style="font-size: 0.8rem;"
                  class="text-center form-control "
                >
                  <label :for="`acumulativo#${index}`">Acumulativo</label>
                  <b-form-checkbox
                    size="sm"
                    :id="`acumulativo#${index}`"
                    :unchecked-value="0"
                    switch
                    :value="1"
                    v-model="item.acumulativo"
                  />
                </b-form-group>
              </template>

              <template #cell(actions)="{ index}">
                <b-btn
                  variant="transparent"
                  size="sm"
                  @click="removeDescontoConvenio(index)"
                >
                  <i class="fas fa-times"></i>
                </b-btn>
              </template>
            </b-table>
            <div class="d-flex justify-content-end mt-0 pt-0">
              <b-btn @click="addDescontoConvenio" size="sm" variant="primary">
                <i class="fas fa-plus"></i> Adicionar Desconto
              </b-btn>
            </div>
          </div>
        </div>

        <div class="bottom-actions" style="position: unset">
          <hr />
          <b-btn variant="primary" class="mr-3" @click="salvar"
            ><i class="fas fa-save"></i> Salvar</b-btn
          >
          <b-btn variant="secondary" @click="fechar()"
            ><i class="fas fa-ban"></i> Cancelar</b-btn
          >
        </div>
      </b-tab>
      <b-tab title="Histórico de Preços" class="p-3">
        <exame-historico-precos :historico="exame.justificativas" />
      </b-tab>
    </b-tabs>

    <b-modal id="selecionar-empresa">
      <template #modal-title>
        Selecionar Entidade
      </template>

      <button-atendimento @click="onSelecionarEmpresa('todas')" class="mb-2">
        <i class="fas fa-globe-americas"></i> Todas
        <small class="text-muted">
          * Irá servir para todas entidades
        </small>
      </button-atendimento>
      <button-atendimento @click="onSelecionarEmpresa('buscar')">
        <i class="fas fa-search"></i> Buscar
        <small class="text-muted">
          * Irá servir para uma entidade especifica
        </small>
      </button-atendimento>
    </b-modal>

    <b-modal id="info" size="xl">
      <template #modal-title>
        Uma ajudinha?
      </template>
      <infoComponent info="valor-fidelizacao-personalizado" />
    </b-modal>
    <b-modal id="search-entidades" size="xl">
      <template #modal-title>
        Buscar Entidade
      </template>
      <SearchEntidades
        @input="onSelectEmpresa"
        :tabs="['medico', 'laboratorio', 'fornecedor', 'convenio']"
      />
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import FidelizacoesLib from "../../../libs/FidelizacoesLib";
import ExameHistoricoPrecos from "../ExameHistoricoPrecos.vue";
import moment from "moment";
import SelectEspecialidade from "../../common/SelectEspecialidade.vue";
import ExamesLib from "../../../libs/ExamesLib";
import SelectExames from "../../common/SelectExames.vue";
import infoComponent from "../../infoComponents/index.vue";
import ButtonAtendimento from "../../common/ButtonAtendimento.vue";
import formasDePagamento from "../../../helpers/formasDePagamento";
import SearchEntidades from "../../common/SearchEntidades.vue";

function historicoFactory(
  data = moment().format("YYYY-MM-DD HH:mm:ss"),
  valor_anterior,
  valor_novo,
  tipo,
  justificativa
) {
  return { data, valor_anterior, valor_novo, tipo, justificativa };
}

export default {
  components: {
    ExameHistoricoPrecos,
    SelectEspecialidade,
    SelectExames,
    infoComponent,
    ButtonAtendimento,
    SearchEntidades,
  },
  mixins: [validationMixin],
  props: {
    editObj: Object,
    editId: [Number, String],
    aceitaFidelizacao: { type: Boolean, default: false },
    entidade_id: { type: [String, Number], required: false },
  },
  data() {
    return {
      exame: {
        id: "",
        nome: "",
        tipo: "",
        especialidade_id: null,
        valor_venda: 0.0,
        valor_custo: 0.0,
        configuracoes: {
          fidelizacoes: [],
          personalizarPorForma: [],
        },
        aceita_desconto: 0,
        personalizar_por_forma: 0,
        justificativas: [],
      },
      optionsPagamento: ["Todas", ...formasDePagamento],
      loadingFidelizacoes: false,
      exame_edit: {},
      lodingExames: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      },
      isLoaded: false,
      editando: false,
      indexExame: null,
      id_entidades_exames: null,
      selecionandoEmpresaPara: null,
      examesDisponiveis: [],
      campos: [
        { key: "nome", label: "Fidelização", class: "text-left text-nowrap" },
        { key: "valor_venda", label: "Valor Venda", class: "text-center" },
        { key: "valor_custo", label: "Valor Custo", class: "text-center" },
      ],
      camposPersonalizarPorForma: [
        {
          key: "formaDePagamento",
          label: "Forma de Pagamento",
          class: "text-left text-nowrap",
        },
        { key: "valor_venda", label: "Valor Venda", class: "text-center" },
        { key: "valor_custo", label: "Valor Custo", class: "text-center" },
        { key: "empresa", label: "Empresa", class: "text-left text-nowrap" },
        { key: "acumulativo", label: "", class: "text-left text-nowrap" },
        { key: "actions", label: "", class: "text-left text-nowrap" },
      ],
      // fidelizacoesPersonalizadas: [

      // ],
      fidelizacoesDisponiveis: [],
    };
  },
  validations: {
    exame: {
      id: { required },
      valor_venda: { required },

      valor_custo: { required },
    },
  },

  methods: {
    limpar() {
      for (const key in this.exame) {
        if (typeof key != "object" && !key._rowStatus) {
          // console.log(`key ${key} typeof ${typeof key}`);
          this.exame[key] = "";
        }
      }
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSelecionarEmpresa(escolha) {
      let escolhas = {
        todas: () => {
          if (this.selecionandoEmpresaPara) {
            this.$set(this.selecionandoEmpresaPara, "id", -1);
            this.$set(this.selecionandoEmpresaPara, "razao_social", "Todas");

            this.closeSelecionarEmpresa();
          }
        },
        buscar: () => {
          this.openBuscaEmpresas();
        },
      };

      escolhas[escolha]();
    },
    selecionarEmpresa(item) {
      this.selecionandoEmpresaPara = item.empresa;
      this.$bvModal.show("selecionar-empresa");
    },
    closeSelecionarEmpresa() {
      this.selecionandoEmpresaPara = null;
      this.$bvModal.hide("selecionar-empresa");
    },
    openInfo() {
      this.$bvModal.show("info");
    },
    openBuscaEmpresas() {
      this.$bvModal.show("search-entidades");
    },
    closeBuscaEmpresas() {
      this.$bvModal.hide("search-entidades");
    },
    onSelectEmpresa(empresa) {
      if (empresa && empresa?.id) {
        this.selecionandoEmpresaPara.id = empresa.id;
        this.selecionandoEmpresaPara.razao_social = empresa.razao_social;
      } else {
        this.selecionandoEmpresaPara.id = "";
        this.selecionandoEmpresaPara.razao_social = "";
      }

      this.closeBuscaEmpresas();
      this.closeSelecionarEmpresa();
    },
    _fidelizacaoPersonalizadaFactory: (
      id,
      nome,
      valor_custo,
      valor_venda,
      desconto
    ) => {
      return {
        id,
        nome,
        valor_custo,
        valor_venda,
        desconto,
      };
    },
    addDescontoConvenio() {
      this.exame.configuracoes.personalizarPorForma.push({
        formaDePagamento: "",
        valor_venda: this.exame?.valor_venda || 0,
        desconto: "",
        valor_custo: this.exame?.valor_custo || 0,
        empresa: { id: "", razao_social: "" },
        acumulativo: 0,
      });
    },
    removeDescontoConvenio(idx) {
      this.exame.configuracoes.personalizarPorForma.splice(idx, 1);
    },
    removeEmpresa(item) {
      item.empresa.id = "";
      item.empresa.razao_social = "";
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.exame[name];
      return $dirty ? !$error : null;
    },
    async carregaExames() {
      this.loadingExames = true;
      this.$forceUpdate();
      await this.$nextTick();
      try {
        this.examesDisponiveis = await ExamesLib.get();
        // console.log("EXAMEES");
      } catch (error) {
        console.log("Erro ao carregar exames", error);
      }
      this.loadingExames = false;
    },
    exameChanged(newExame) {
      if (newExame && this.exame) {
        this.exame.nome = newExame.nome;
        this.exame.valor_custo = newExame.valor_custo || 0;
        this.exame.valor_venda = newExame.valor_venda || 0;
        this.exame.id = newExame.id;
        this.exame.tipo = newExame.tipo;
        this.exame.especialidade_id = newExame.especialidade_id;
        this.exame.configuracoes.fidelizacoes = [];
        this.exame.configuracoes.personalizarPorForma = [];
      }
    },
    validar() {
      //       exame: {
      //   id: { required },
      //   valor_venda: { required },

      //   valor_custo: { required },
      // },
      const errors = [];
      if (!this.exame.nome) {
        errors.push("O nome do exame é obrigatório");
      }

      if (typeof this.exame.valor_custo != "number") {
        errors.push("O valor de custo é inválido");
      }

      // if (!this.exame.valor_venda) {
      //   errors.push("O valor de venda é obrigatório");
      // }

      if (!this.exame.tipo) {
        errors.push("O tipo é obrigatório");
      }

      if (!this.exame.tipo) {
        errors.push("O tipo é obrigatório");
      }

      if (this.exame.aceita_desconto) {
        if (Array.isArray(this.exame.configuracoes.fidelizacoes)) {
          if (
            this.exame.configuracoes.fidelizacoes.some(
              (fid) =>
                fid.valor_venda == null || typeof fid.valor_venda != "number"
            )
          ) {
            errors.push(
              "O valor de desconto personalizado das fidelizações não podem ser vazios. Por favor, verifique-os."
            );
          }
        }
      }

      if (this.exame.personalizar_por_forma) {
        if (
          Array.isArray(this.exame.configuracoes?.personalizarPorForma) &&
          this.exame.configuracoes.personalizarPorForma.length > 0
        ) {
          let withError =
            this.exame.configuracoes?.personalizarPorForma
              ?.map((desc, i) => ({ position: i + 1, ...desc }))
              .filter(
                (desc) =>
                  desc.valor_venda == null ||
                  typeof desc.valor_venda != "number" ||
                  !desc.empresa.razao_social ||
                  !desc.empresa.id ||
                  !desc.formaDePagamento
              ) || [];

          if (withError.length > 0) {
            errors.push(
              withError.map((d) => {
                if (!d.empresa.id || !d.empresa.razao_social) {
                  errors.push(
                    `O desconto por forma de pagamento na posição <b>#${d.position}</b> está inválido, por favor, <b>selecione uma empresa</b>.`
                  );
                }
                if (!d.formaDePagamento) {
                  errors.push(
                    `O desconto por forma de pagamento na posição <b>#${d.position}</b> está inválido, por favor, <b>selecione uma forma de pagamento</b>.`
                  );
                }
              })
            );
          }
        } else {
          errors.push(
            "O <b>Desconto por Forma de Pagamento</b> está ativo e não há nenhum desconto adicionado, adicione um desconto ou desative o  <b>Desconto por Forma de Pagamento</b>"
          );
        }
      }
      if (errors.length > 0) {
        let errorDesc = errors.reduce((ret, vl) => {
          ret += vl + "<hr/>";
          return ret;
        }, "");
        this.$swal({
          title: "Atenção",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errorDesc}</span>`,
          icon: "error",
          allowEscapeKey: true,
        });
      }
      return errors.length === 0;
    },
    async selecionaExame(exame) {
      await this.$nextTick();
      this.$forceUpdate();
      this.limpar();

      Object.assign(this.exame, exame);
    },
    async salvar() {
      this.$forceUpdate();
      await this.$nextTick();
      if (this.editando) {
        if (this.exame.valor_venda != this.exame_edit.valor_venda) {
          const { value: justificativa } = await this.$swal.fire({
            title: "Justifique a alteração no valor de venda.",
            input: "text",
            inputLabel: "Justificativa",
            inputPlaceholder: "Digite a justificativa",
            inputValidator: (value) => {
              if (!value) {
                return "A justificativa é obrigatória";
              }
            },
          });

          if (justificativa) {
            this.exame.justificativas.push({
              ...historicoFactory(
                moment().format("YYYY-MM-DD HH:mm:ss"),
                this.exame_edit.valor_venda,
                this.exame.valor_venda,
                "venda",
                justificativa
              ),
              _rowStatus: "added",
            });
          } else {
            return;
          }
        }
        if (this.exame.valor_custo != this.exame_edit.valor_custo) {
          const { value: justificativa } = await this.$swal.fire({
            title: "Justifique a alteração no valor de custo.",
            input: "text",
            inputLabel: "Justificativa",
            inputPlaceholder: "Digite a justificativa",
            inputValidator: (value) => {
              if (!value) {
                return "A justificativa é obrigatória";
              }
            },
          });

          if (justificativa) {
            this.exame.justificativas.push({
              ...historicoFactory(
                moment().format("YYYY-MM-DD HH:mm:ss"),
                this.exame_edit.valor_custo,
                this.exame.valor_custo,
                "custo",
                justificativa
              ),
              _rowStatus: "added",
            });
          } else {
            return;
          }
        }
      }

      if (this.validar()) {
        let exame = Object.assign({}, this.exame);

        // this.exame.aceita_desconto
        //   ? (exame.configuracoes.fidelizacoes = this.fidelizacoesPersonalizadas)
        //   : (exame.configuracoes.fidelizacoes = []);
        // console.log("exame adicionar", exame);
        this.$emit("salvar", {
          ...exame,
          _rowStatus:
            this.editando && exame._rowStatus != "added" ? "edited" : "added",
        });
      }
    },
    editarExame() {
      this.$emit("editar", this.exame.id);
    },
    editJustificativa() {
      this.$bvModal.show("modal-justificativa");
    },
    fechar() {
      this.$bvModal.hide("modal-exames-select");
    },
    async carregaFidelizacoes() {
      this.$forceUpdate();

      this.fidelizacoesDisponiveis = await FidelizacoesLib.get();
    },
    async onPersonalizaDesconto() {
      this.loadingFidelizacoes = true;
      if (this.exame.aceita_desconto == 0) {
        this.exame.configuracoes.fidelizacoes = [];
        return;
      }
      await this.carregaFidelizacoes();
      if (this.fidelizacoesDisponiveis.length > 0) {
        this.fidelizacoesDisponiveis.map((fid) => {
          if (
            !this.exame?.configuracoes?.fidelizacoes.some(
              (f) => f.id === fid.id
            )
          ) {
            const vendaComDesconto = FidelizacoesLib.aplicaDescontoByObj({
              fidelizacao: fid,
              valor: this.exame.valor_venda ? this.exame.valor_venda : "0",
              tipoProduto: this.exame.tipo,
            });
            this.exame.configuracoes.fidelizacoes.push(
              this._fidelizacaoPersonalizadaFactory(
                fid.id,
                fid.nome,
                this.exame?.valor_custo || 0,
                vendaComDesconto.valor,
                vendaComDesconto.desconto
              )
            );
          }
        });
      }

      this.loadingFidelizacoes = false;
    },
  },

  computed: {
    changedValorVenda() {
      return this.exame.valor_venda != this.exame_edit.valor_venda;
    },
    changedValorCusto() {
      return this.exame.valor_custo != this.exame_edit.valor_venda;
    },
  },

  async created() {
    this.$forceUpdate();
    await this.$nextTick();
    await this.carregaFidelizacoes();

    //  console.log("exame_edit", this.exame_edit);
    if (this.editObj && this.editObj.id) {
      this.exame_edit = this.editObj;
      this.exame = Object.assign(this.exame, this.editObj);
      this.editando = true;
    }

    // console.log("exame", this.exame);
    this.isLoaded = true;
  },
  watch: {
    "exame.aceita_desconto"() {
      this.onPersonalizaDesconto();
    },
    "exame.personalizar_por_forma"() {
      if (this.exame.personalizar_por_forma === 0) {
        this.exames.configuracoes.personalizarPorForma = [];
      }
    },
  },
};
</script>

<style></style>
