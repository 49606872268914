<template>
  <div>
    <component :is="infoComponent[info]"></component>
  </div>
</template>

<script>
import Default from './SubComponents/Default.vue';
import valorFidelizacaoPersonalizado from './SubComponents/valorFidelizacaoPersonalizado.vue';
export default {
  props: {
    info: {type: [String],default: 'default'}
  },
  data(){
    return{
      infoComponent: {
        'default': Default,
        'valor-fidelizacao-personalizado':valorFidelizacaoPersonalizado
      }
    }
  }
};
</script>

<style></style>
